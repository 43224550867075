import Vue from 'vue'
import VueRouter from 'vue-router'


import axios from "axios";
import store from "../store"


Vue.use(VueRouter)

//解决导航报错的问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/home.vue'),
    redirect:'/index',
    children:[
        {
          path: '/index',
          name: '首页',
          component: () => import('@/views/index1.vue'),
        }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login.vue')
  }
]
  const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
  })


//路由拦截器
router.beforeEach(async (to, from, next) => {
    //用于判断当前用户是否已经获取过路由（如果已经获取过就不在请求获取）
    let hasRoute = store.state.menus.hasRoutes
    let token = sessionStorage.getItem("token")
      if (to.path == '/login') {
          next()
      }else if (!token) {
        next({path: '/login'})
      }else if(token && !hasRoute) { //如果有token并且是第一次登录
       await axios({
                method:'get',
                headers: {
                  contentType : 'application/json;charset=UTF-8',
                  token:token
                },
                url:"https://wokeyanglao.cn:8443/crm/menu/getMenuByUser"
              }).then((res) => {
                  if(res.data.code==10000){
                    store.commit("setMenuList",res.data.data)
                    let newRoutes = router.options.routes
                      if(res.data.data){
                          res.data.data.forEach(menu => {
                              if (menu.children) {
                                  menu.children.forEach(item => {
                                    let route = menuToRoute(item)
                                      if (route) {
                                        newRoutes[0].children.push(route)
                                      }
                                  })
                              }
                          })
                    }
                    //添加动态路由之前清除掉静态路由里面的数据
                    router.matcher = new VueRouter().matcher 
                    //添加动态路由
                    router.options.routes.forEach(res=>{
                          router.addRoute(res)
                    })
                      //将状态设置为已经获取过（下次再次访问将不在获取）
                      hasRoute = true;
                      store.commit("changeRouteStatus", hasRoute)
                    }
                })
                //跳转到下个路由
                 next({path:to.path})   
            } else{
              next()   
            }
          
})

//导航转成路由（动态路由）
const menuToRoute = (menu) => {
  //判断是否有component(1级是没有component的，如果没有就直接返回null)
	if (!menu.component) {
		return null
	}
  //由菜单组装 动态路由
	let route = {
		path: menu.path,  //设置路由的路径
    name: menu.name,  //设置路由的名字
		meta: {
		  	icon: menu.icon, 
			  title: menu.name
		}
	}

	route.component = () => import('@/views/'+  menu.component +'.vue')

	return route
}



export default router
