<template>
  <div id="app">
    
    <router-view/>
  </div>
</template>
<script>

export default{
  data(){
    return{

    }
  },
  watch: {
    //监听路由
			$route(to, from) {
				if (to.path != '/login' && to.name!="404" && to.name!="首页") {
					let obj = {
						name: to.name,
						title: to.meta.name
					}
					this.$store.commit("addTab", obj)
				}

			}
  },
}
</script>

<style>
  #app {
    -moz-user-select: none; /*火狐*/
    -webkit-user-select: none; /*webkit浏览器*/
    -ms-user-select: none; /*IE10*/
    -khtml-user-select: none; /*早期浏览器*/
    user-select: none;
  }
</style>
