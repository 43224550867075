import Vue from "vue"

Vue.mixin({
	methods: {
		hasAuth(perm) {
			var authority = JSON.parse(sessionStorage.getItem("permList"))
			return authority.indexOf(perm) > -1
		}
	},
	//过滤时间
	filters: {
		formatDate: function (value) {
			let date = new Date(value);
			let y = date.getFullYear();
			let MM = date.getMonth() + 1;
			MM = MM < 10 ? ('0' + MM) : MM;
			let d = date.getDate();
			d = d < 10 ? ('0' + d) : d;
			let h = date.getHours();
			h = h < 10 ? ('0' + h) : h;
			let m = date.getMinutes();
			m = m < 10 ? ('0' + m) : m;
			let s = date.getSeconds();
			s = s < 10 ? ('0' + s) : s;
			return y + '-' + MM + '-' + d;
		},
		//年月日 时分秒
		allDate: function (value) {
			let date = new Date(value);
			let y = date.getFullYear();
			let MM = date.getMonth() + 1;
			MM = MM < 10 ? ('0' + MM) : MM;
			let d = date.getDate();
			d = d < 10 ? ('0' + d) : d;
			let h = date.getHours();
			h = h < 10 ? ('0' + h) : h;
			let m = date.getMinutes();
			m = m < 10 ? ('0' + m) : m;
			let s = date.getSeconds();
			s = s < 10 ? ('0' + s) : s;
			return y + '-' + MM + '-' + d+ ' ' + ' ' + ' ' + h + ':' + m + ':' + s;
		},
		reduceUrl:function (url){
			url = '/' + url.split('/').slice(3).join('/');
		}
	}
})

