import Vue from 'vue'
import Vuex from 'vuex'


Vue.use(Vuex)

export default {

	state: {
		menuList: [],
		permList: [],
		userInfo:{},
		hasRoutes: false,
		//tabs高亮
		editableTabsValue: 'index',
		//存放tabs标签
		editableTabs: [{
			title:"首页",
			name:"home"
		}]
	},
	mutations: {
		setMenuList(state, menus) {
			state.menuList = menus;
			//将菜单存入session
			sessionStorage.setItem("menuList", JSON.stringify(menus))
		},
		setPermList(state, perms) {
			state.permList = perms;
			sessionStorage.setItem("permList", JSON.stringify(perms))
		},
		setUser(state,user){
			state.userInfo = user;
			sessionStorage.setItem("userInfo", JSON.stringify(user))
		},
		//用于当前动态路由是否已经获取如果vuex已经有值就不在请求
		changeRouteStatus(state, hasRoutes) {
			state.hasRoutes = hasRoutes
			sessionStorage.setItem("hasRoutes",JSON.stringify(hasRoutes))
		},
		
		//增加tabs标签
		addTab(state,tab) {
			//判断editableTabs里面有没有当前传入的值,没找到再增加		
			let index = state.editableTabs.findIndex(e => e.name === tab.name)
			if (index === -1) {
				state.editableTabs.push({
					title: tab.name,
					name: tab.name,
				});
			}
			//将当前的高亮设置为当前点击的菜单
			state.editableTabsValue = tab.name;
		},
		//当退出，清空state里面的值
		resetState: (state) => {
			state.menuList = []
			state.permList = []
			state.hasRoutes = false
			state.editableTabsValue = 'index'
			state.editableTabs = [{
				title: '首页',
				name: 'index',
			}]
		}

	},
	actions: {
	},

}